<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb" style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229)">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-flex uk-flex-middle">
            <h1 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle" style="color: rgb(51, 51, 51)">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
              Projekte
            </h1>
          </div>
          <div v-if="$can('manage', 'all') || $can('create', 'projects')">
          <router-link
            :to="{ name: 'project-edit' }"
            class="uk-button uk-button-primary uk-button-small uk-border-rounded"
          >
            <span uk-icon="plus-circle"></span>
            Neues Projekt erstellen
          </router-link>
        </div>
        </div>
      </div>
    </div>
    <div class="uk-section uk-section-small">
      <div class="uk-container uk-container-expand">
        <div class="uk-grid uk-grid-small" data-uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-card uk-card-default uk-margin-large-bottom">
              <div class="uk-card-body uk-padding-remove">
                <div class="uk-padding-small">
                  <div class="uk-grid uk-grid-small uk-flex uk-flex-middle">
                    <div class="uk-width-auto">
                      <search v-slot="{ props, activateFilter }">
                        <div class="uk-width-medium">
                          <input 
                            type="text" 
                            v-model="props.customer" 
                            placeholder="Kunde" 
                            @keyup.enter.prevent="activateFilter"
                            class="uk-input uk-border-rounded">
                        </div>
                        <div class="uk-width-medium">
                          <input 
                            type="text" 
                            v-model="props.number" 
                            placeholder="Projektnummer" 
                            @keyup.enter.prevent="activateFilter"
                            class="uk-input uk-border-rounded">
                        </div>
                      </search>
                    </div>
                  </div>
                </div>
                <hr> 
                <table
                  class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                  v-if="projects.length"
                >
                  <thead>
                    <tr>
                      <th class="uk-table-expand">Name</th>
                      <th class="uk-table-shrink"></th>
                      <th style="width: 60px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="uk-visible-toggle"
                      v-for="project in projects"
                      :key="project.id"
                    >
                      <td>
                        <router-link
                          :to="{
                            name: 'project-edit',
                            params: { id: project.id },
                          }"
                        >
                          {{ project.number }} {{ project.details.data.lastname }} {{ project.details.data.city }}
                        </router-link>
                      </td>
                      <td class="uk-text-right@m">
                        {{
                            new Date(project.date)
                            .toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        }}
                      </td>
                      <td class="uk-text-nowrap uk-text-right">
                        <div class="uk-margin-small-right">
                          <a href="#" class="uk-icon-link" uk-icon="more"></a>
                          <div data-uk-dropdown="mode: click;animation: uk-animation-slide-top-small;pos: bottom-right">
                            <ul class="uk-nav uk-dropdown-nav uk-text-left">
                              <li v-if="$can('manage', 'all') || $can('edit', 'projects')">  
                                <router-link
                                class="uk-dropdown-close"
                                  :to="{
                                    name: 'project-edit',
                                    params: { id: project.id },
                                  }"
                                >
                                Bearbeiten
                                </router-link>
                              </li>
                              <li class="uk-nav-divider uk-margin-remove" v-if="$can('manage', 'all') || $can('delete', 'projects')"></li>
                              <li v-if="$can('manage', 'all') || $can('delete', 'projects')">
                                <a
                                  class="uk-dropdown-close uk-text-danger"
                                  href="#"
                                  @click.prevent="remove(project.id)"
                                >
                                Löschen
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="meta && meta.pagination.total_pages > 1" class="uk-padding-small">
                  <pagination :pagination="meta.pagination"></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-hidden@m uk-inline">
          <div uk-margin class="uk-position-bottom uk-position-fixed uk-padding-small uk-text-center" style="z-index: 10;
          border-top: 1px solid #d6d4d4;
          background: #f7f7f7;
          padding-bottom: 25px;">
          <router-link
            :to="{ name: 'project-edit' }"
            class="uk-button uk-button-primary uk-border-rounded"
          >
            <span uk-icon="plus-circle"></span>
            Neu
          </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectService from '@/services/project.service.js'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import Search from '@/components/Search'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      projects: [],
      checked: [],
      allSelected: false,
      meta: null,
    }
  },
  components: {
    Search,
    Pagination
  },
  mounted() {
    this.getProjects()
  },
  watch: {
    "$route.query": {
      handler() {
        this.getProjects();
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    getProjects() {
      ProjectService.getList({ ...this.$route.query}).then((response) => {
        this.projects = response.data.data
        this.meta = response.data.meta
      })
    },
    remove(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Projekt löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function() {
            ProjectService.delete(id).then(() => {
              iziToast.success({
                title: 'OK',
                message: 'Projekt gelöscht!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })
              $this.getProjects()
            })
          },
          function() {
            console.log('Rejected.')
          }
        )
    },
  },
}
</script>
